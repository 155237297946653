<template>
  <v-card min-width="100%" class="mx-auto" raised @click="open()">
    <div class="card">
    <v-img :src="url" :min-height="minheight" :max-height="height" class="card-galery"></v-img>
    </div>
    <v-list-item-content class="mx-2">
      <v-list-item-title class="headline-4 mb-4"><strong>{{title}}</strong></v-list-item-title>
      <v-list-item-subtitle>{{date}}</v-list-item-subtitle>
    </v-list-item-content>
    
  </v-card>
</template>

<script>
export default {
  name: "CardGallery",
  props:{
    id:Number,
    title: String,
    date: String,
    url: String,
    height:String,
    minheight:String
  },
  data: () => ({
    
  }),
   methods:{
    open(){
      this.$emit('open', this.id)
    }
  }
};
</script>

<style lang="sass" scoped>
  .card
    background-color: #000 
    background-image: url('../../assets/img.svg') 
    background-repeat: no-repeat
    background-position: center
    background-size:50% 
    transition: 
      property: opacity
      duration: 1s

    &:hover 
      opacity: .8
      
  .card-galery
    transition: 
      property: opacity
      duration: 1s

    &:hover 
      opacity: .5
</style>